import '@ungap/custom-elements';

class CountdownElement extends HTMLTimeElement {
    #timer;
    #endDate;

    constructor() {
        super();
    }

    /** Called each time the element is added to the document. */
    connectedCallback() {
        const dateAttribute = this.getAttribute('datetime');

        if (!dateAttribute) {
            return;
        }

        this.#endDate = new Date(dateAttribute);
        this.#render();
    }

    disconnectedCallback() {
        this.#clearTimer();
    }

    #clearTimer() {
        if (this.#timer) {
            clearTimeout(this.#timer);
        }
    }

    #runTimer() {
       this.#clearTimer();

        this.#timer = setTimeout(() => {
            this.#render();
        }, 1000);
    }

    #render() {
        const now = Date.now();
        const remaining = this.#endDate - now;

        if (remaining <= 0) {
            this.remove();
        }

        const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remaining % (1000 * 60)) / 1000);

        let longTimeString = '';
        let shortTimeString = '';

        if (days) {
            longTimeString += ` ${days} day${days > 1 ? 's' : ''}`;
            shortTimeString += ` ${days} day${days > 1 ? 's' : ''}`;
        }

        if (days || hours) {
            longTimeString += ` ${hours} hour${hours > 1 ? 's' : ''}`;
            shortTimeString += ` ${hours}h`;
        }

        if (days || hours || minutes) {
            longTimeString += ` ${minutes} min${minutes > 1 ? 's' : ''}`;
            shortTimeString += ` ${minutes}m`;
        }

        if (days || hours || minutes || seconds) {
            longTimeString += ` ${seconds} sec${seconds > 1 ? 's' : ''}`;
            shortTimeString += ` ${seconds}s`;
        }

        this.innerHTML = `
            Ends in: <span class="inline-block">
                <span class="sm:hide">${longTimeString}</span>
                <span class="sm:show">${shortTimeString}</span>
            </span>
        `;

        this.#runTimer();
    }
}

customElements.define('ixdf-countdown', CountdownElement, {extends: 'time'});
