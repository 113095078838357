/**
 * @component components/tabs
 * @description Register event handlers tabs component.
 */

/**
 * @param {HTMLDivElement} tabComponent
 */
function addEventListeners(tabComponent) {
    tabComponent.addEventListener('click', function (clickEvent) {
        clickEvent.preventDefault();
        const isClickedByTabLink = clickEvent.target.classList.contains('js-tabs__itemLink');
        if (!isClickedByTabLink) {
            return;
        }

        const previousActiveLink = tabComponent.querySelector('.tabs__item--active .js-tabs__itemLink');
        switchTab(clickEvent.target, previousActiveLink);
    });
}

/**
 * @param {HTMLAnchorElement} newActiveLink
 * @param {HTMLAnchorElement} previousActiveLink
 */
function switchTab(newActiveLink, previousActiveLink) {
    if (newActiveLink.isSameNode(previousActiveLink)) {
        return;
    }

    /** @type {HTMLLIElement} */
    const newActiveLi = newActiveLink.closest('[data-tab-item]');
    newActiveLi.classList.add('tabs__item--active');
    newActiveLink.classList.ariaSelected = true;
    /** @type {HTMLElement} */
    const newActiveTabContent = document.querySelector(new URL(newActiveLink.href).hash);
    newActiveTabContent.classList.add('tabs__singleTabContent--active');
    newActiveTabContent.hidden = false;

    /** @type {HTMLLIElement} */
    const previousActiveLi = previousActiveLink.closest('[data-tab-item]');
    previousActiveLi.classList.remove('tabs__item--active');
    previousActiveLink.classList.ariaSelected = false;
    /** @type {HTMLElement} */
    const previousActiveTabContent = document.querySelector(new URL(previousActiveLink.href).hash);
    previousActiveTabContent.classList.remove('tabs__singleTabContent--active');
    previousActiveTabContent.hidden = true;
}

/** */
function initializeComponent() {
    [...document.querySelectorAll('.js-tabs--samePage')].forEach(addEventListeners);
}

document.readyState === 'loading'
    ? document.addEventListener('DOMContentLoaded', initializeComponent)
    : initializeComponent();
