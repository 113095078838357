/**
 * please think twice before adding a new app-level dependency,
 * it’ll have effect to the pageload and parsing time of the app
 */

import {Button} from './modules/button.js';
import {Notification} from './modules/notification.js';
import {restyleAllInputElements} from './modules/form.js';
import {Tooltip} from './modules/tooltip.js';
import {CookieManager} from './modules/cookie.js';
import {Auth} from './modules/auth.js';
import {AnalyticsListener} from './modules/analyticsListener.js';
import {manageNonSubscriberContent} from './modules/manageNonSubscriberContent.js';
import './components/stickyToBottomContainer.js';
import './components/cta.js';
import './components/formValidation.js';
import './components/passwordField.js';
import './modules/lazyLoader.js';
import './modules/datepicker.js';
import './components/adArea/adArea.js';
import './components/countdown.js';
import './components/newsletterCountdown.js';
import './components/tabs.js';
import './components/header.js';
import './components/lottieAnimation.js';
import './components/offcanvas.js';
import './components/videoTranscript.js';
import './components/richTextEditor/element.js';
import './components/search/modalSearch.js';
import './components/tocContainer.js';

import dialogPolyfill from 'dialog-polyfill';
import {setupDialogSupport} from './components/dialog.js';

/**
 * Delay (in ms) which is used to postpone a preloading of video component’s source code when the current page
 * contains at least a single video to play. The reason to have such delay is to be sure all major sources
 * have been loaded already on initial page load, and it won’t consume extra CPU time in a critical moment.
 * Meanwhile, if the page is being scrolled to any of its videos then the video component’s source code will be
 * requested by {@see modules/lazyLoader} immediately without any delay using Webpack’s dynamic import.
 */
const DELAY_TO_PRELOAD_VIDEO_COMPONENT = 3000;

/** Selector for elements that represent playable videos on a page. */
const VIDEO_ELEMENTS_SELECTOR =
    'video,iframe[data-src*=youtube],iframe[src*=youtube],iframe[src*=vimeo],iframe[data-src*=vimeo]';

/**
 * Display Alerts based on elements with data-alert attribute
 */
function displayAlerts() {
    [...document.querySelectorAll('[data-alert]')].forEach(
        /** @param {HTMLElement} element */
        (element) => {
            Notification[element.dataset.alertType](
                {message: element.dataset.alertMessage, title: element.dataset.alertTitle},
                element.parentElement
            );
        }
    );
}

/**
 * Register event handler to log important events to an external service
 * Events dispatched using code like: window.dispatchEvent(new CustomEvent('log:warning', {detail}));
 */
function logEvents() {
    ['error', 'warning', 'info'].forEach((severity) => {
        window.addEventListener(
            `log:${severity}`,
            /** @param {CustomEvent} event */
            (event) => {
                // @ts-ignore
                const bugsnag = window.Bugsnag;
                if (!bugsnag) {
                    // eslint-disable-next-line no-console
                    console[severity === 'warning' ? 'warn' : severity](event.detail);
                    return;
                }

                /** @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/ */
                let notification = event.detail;
                if (!notification) {
                    // eslint-disable-next-line no-console
                    console.error('Event detail must be specified for logging');
                    return;
                }

                bugsnag.notify(notification, (event) => {
                    event.severity = severity;

                    const metadata = notification.metadata;
                    if (typeof metadata === 'object') {
                        Object.keys(metadata).forEach((key) => {
                            event.addMetadata(key, metadata[key]);
                        });
                    }
                });
            }
        );
    });
}

/**
 * Preloads a source code for video component only if there are any videos on the page.
 * The initialization of a player instance for videos is up to {@see components/video}.
 */
function preloadVideoComponent() {
    if (document.querySelector(VIDEO_ELEMENTS_SELECTOR)) {
        setTimeout(() => import('./components/video.js'), DELAY_TO_PRELOAD_VIDEO_COMPONENT);
        // TODO: Check if this timeout is really required
        // Another option: (()=>{import('./components/video.js')})();
        // Slack Thread: https://interaction-design.slack.com/archives/C04AT30UJ/p1719931965704159
    }
}

/** */
function lazyLoadCollapsibleTextComponents() {
    const collapsibleOverflowTextComponents = document.querySelectorAll('[data-collapsible-overflow-text]');
    if (collapsibleOverflowTextComponents.length) import('./components/collapsibleOverflowText.js');
}

/** */
function initPageAnnouncementCloseButton() {
    const pageAnnouncementCloseButtons = [...document.querySelectorAll('[data-page-announcement-close-button]')];

    pageAnnouncementCloseButtons.forEach((pageAnnouncementCloseButton) => {
        pageAnnouncementCloseButton.addEventListener('click', (event) => {
            const eventTarget = /** @type {HTMLElement} */ (event.target);
            /** @type {HTMLElement} */
            const pageAnnouncement = eventTarget.closest('[data-page-announcement]');
            if (pageAnnouncement.dataset.dismissibleId) {
                // @ts-ignore
                CookieManager.write(`dismissed_announcement:${pageAnnouncement.dataset.dismissibleId}`, null, {
                    days: 7,
                    secure: true,
                });
            }

            pageAnnouncement.classList.add('hidden');
        });
    });
}

/**
 * Display Localized Time As Tooltip
 */
function displayLocalizedTimeAsTooltip() {
    const formats = {
        datetime: {
            weekday: 'short',
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'long',
            hourCycle: 'h24',
        },
        time: {
            hour: '2-digit',
            minute: '2-digit',
            timeZoneName: 'long',
            hourCycle: 'h12',
        },
    };

    [...document.querySelectorAll('.js-timestampToToolTip')].forEach(
        /** @param {HTMLElement} element */
        (element) => {
            const timestamp = element.dataset.timestamp;
            if (!timestamp) {
                return;
            }
            const format = element.dataset.format || 'datetime';
            const options = formats[format];
            const date = new Date(Number(timestamp) * 1000);
            element.dataset.title = date.toLocaleString(undefined, options);
        }
    );
}

/**
 * Setup service worker
 */
function registerServiceWorker() { // eslint-disable-line no-unused-vars
    if ('serviceWorker' in navigator) {
        // Use the window load event to keep the page load performant
        window.addEventListener('load', () => {
            const serviceWorker = navigator.serviceWorker;
            if (serviceWorker && serviceWorker instanceof ServiceWorkerContainer) {
                serviceWorker.register('/build/sw.js');
            }
        });
    }
}

/** To make the app more interactive with dialogs, the app is listening for some event. */
function listenDialogManagementEvents() {
    /**
     * Experimental functionality. If you found it used in 2025, please remove it.
     * Sometimes it's safer/more useful to emit event instead of writing custom JS logic (e.g. HTMLX, inline onlick JS)
     */
    document.addEventListener('openDialog', (/** @type {CustomEvent} */ event) => {
        const dialog = document.getElementById(event.detail.dialogId);
        if (dialog instanceof HTMLDialogElement) {
            dialog.showModal();
        } else {
            throw new Error(`Dialog with id ${event.detail.dialogId} not found`);
        }
    });
}

/**
 * Init application
 */
function runApp() {
    setupDialogSupport(dialogPolyfill);
    listenDialogManagementEvents();

    if (Auth.check()) {
        import('./modules/sessionVerifier.js');
    }

    Button.bindInteractiveButtons();
    Tooltip.applyTo(document.body);

    restyleAllInputElements();
    displayAlerts();
    logEvents();
    preloadVideoComponent();
    lazyLoadCollapsibleTextComponents();
    initPageAnnouncementCloseButton();
    displayLocalizedTimeAsTooltip();
    manageNonSubscriberContent();
    // registerServiceWorker();

    new AnalyticsListener().registerListeners();
}

// init page when the document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', runApp) : runApp();

// @ts-ignore
if (import.meta.env.VITE_APP_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.info(
        '------------------------------------------------------------------------\n\nWant to help us write even better code?\n\nThen come work with us: https://www.interaction-design.org/about/careers\n\n------------------------------------------------------------------------'
    );
}
