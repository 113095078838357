const MENU_STATE_CHANGE_EVENT_NAME = 'offCanvasMenu:stateChange';

class OffCanvasMenu {
    static get OPEN_CLASS() {
        return 'menu-open';
    }

    constructor() {
        this.openBtn = document.querySelector('.js-openOffCanvas');
        this.menuWrap = document.querySelector('.offCanvasWrapper');
        this.menu = document.querySelector('.offCanvasMenu');

        if (!this.menu) {
            return;
        }

        this.onClick = this.onClick.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
        this.addEventListeners();
    }

    addEventListeners() {
        this.openBtn.addEventListener('click', this.onClick);
        this.menuWrap.addEventListener('click', this.onClickOutside);
        document.addEventListener('modalSearch:openCloseStateChange', this.#onModalSearchOpenCloseStateChange);
    }

    onClick() {
        if (this.menuWrap.classList.contains(OffCanvasMenu.OPEN_CLASS)) {
            this.closeMenu();
        } else {
            this.openMenu();
        }
    }

    onClickOutside(clickEvent) {
        if (
            this.menu.contains(clickEvent.target) ||
            this.openBtn.contains(clickEvent.target) ||
            !this.menuWrap.classList.contains(OffCanvasMenu.OPEN_CLASS)
        ) {
            return;
        }
        this.closeMenu();
    }

    openMenu() {
        this.#setToggleButtonState(true);
        this.menuWrap.classList.add(OffCanvasMenu.OPEN_CLASS);
        document.querySelector('[data-page-announcements-mobile]')?.classList.add('invisible');
        this.#triggerStateChangeEvent(true);
    }

    closeMenu() {
        this.#setToggleButtonState(false);
        this.menuWrap.classList.remove(OffCanvasMenu.OPEN_CLASS);
        document.querySelector('[data-page-announcements-mobile]')?.classList.remove('invisible');
        this.#triggerStateChangeEvent(false);
    }

    #setToggleButtonState(open = true) {
        if (open === false) {
            this.openBtn.querySelector('.js-openSmallScreenMenu').classList.remove('hidden');
            this.openBtn.querySelector('.js-closeSmallScreenMenu').classList.add('hidden');
        } else {
            this.openBtn.querySelector('.js-openSmallScreenMenu').classList.add('hidden');
            this.openBtn.querySelector('.js-closeSmallScreenMenu').classList.remove('hidden');
        }
    }

    #onModalSearchOpenCloseStateChange = (event) => {
        if (event.detail.modalIsOpen) {
            this.closeMenu();
        }
    };

    /**
     * Dispatch event indicating change in menu open/close state
     * @param {boolean} open
     */
    #triggerStateChangeEvent(open) {
        document.dispatchEvent(
            new CustomEvent(MENU_STATE_CHANGE_EVENT_NAME, {
                detail: {
                    menuIsOpen: open,
                },
            })
        );
    }
}

new OffCanvasMenu();
