function initializeCountdowns() {

    function initializeCountdown(/** @type {HTMLTimeElement} */ countdown) {
        // Wednesday
        const targetDayIndex = 3;

        const currentTime = new Date();
        const targetTime = new Date();

        // Set time to noon UTC
        targetTime.setUTCHours(12);
        targetTime.setUTCMinutes(0);
        targetTime.setUTCSeconds(0);

        // Set date for the following Wednesday UTC
        targetTime.setDate(targetTime.getUTCDate() + (targetDayIndex + 7 - targetTime.getUTCDate()% 7) );

        // The following Wednesday is more than one week
        if (targetTime.getTime() - currentTime.getTime() > 7 * 24 * 60 * 60 * 1000) {
            // Change date to one week earlier
            targetTime.setDate(targetTime.getUTCDate() - 7 );
        }

        function updateTime() {
            const timeDifference = targetTime.getTime() - Date.now();

            const hours = Math.max(Math.floor(timeDifference / (1000 * 60 * 60)), 0);
            const minutes = Math.max(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)), 0);
            const seconds = Math.max(Math.floor((timeDifference % (1000 * 60)) / 1000), 0);

            let hoursString = `${hours}`;

            while (hoursString.length < 2) {
                hoursString = `0${hoursString}`;
            }

            let minutesString = `${minutes}`;

            while (minutesString.length < 2) {
                minutesString = `0${minutesString}`;
            }

            let secondsString = `${seconds}`;

            while (secondsString.length < 2) {
                secondsString = `0${secondsString}`;
            }

            countdown.innerHTML = `
                <span class="imitateMonospace" data-monospaced-content="${hoursString}">
                    <span class="imitateMonospace__container">${hoursString}</span>
                </span> hr${hours !== 1 ? 's' : ''}
                <span class="imitateMonospace" data-monospaced-content="${minutesString}">
                    <span class="imitateMonospace__container">${minutesString}</span>
                </span> min${minutes !== 1 ? 's' : ''}
                <span class="imitateMonospace" data-monospaced-content="${secondsString}">
                    <span class="imitateMonospace__container">${secondsString}</span>
                </span> sec${seconds !== 1 ? 's' : ''}`;

            if (timeDifference > 0) {
                setTimeout(updateTime, 1000);
            }
        }

        updateTime();
    }

     const timeElements = document.querySelectorAll('.js-newsletterCountdown');

     timeElements.forEach(initializeCountdown);
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initializeCountdowns) : initializeCountdowns();
